import React from 'react';

import {
  Box,
  Grid,
  VStack,
  Text,
  Image,
  Center,
  Heading,
  HStack,
  Button,
  Link,
  IconButton,
  Badge,
} from '@chakra-ui/react';

import { Link as ReactRouterLink } from 'react-router-dom';

import { QuestionIcon } from '@chakra-ui/icons';

const logo = require('../../../../assets/png/logo.png');
const aemetLogo = require('../../../../assets/png/logoAemet.png');

export default function Header() {
  return (
    <header>
      <Box
        position={'absolute'}
        display={'flex'}
        w={['95%', null, null, null, '50%']}
        ml={['2.5%', null, null, null, '25%']}
        alignItems={'center'}
        justifyContent={'flex-end'}
      >
        <Link as={ReactRouterLink} to={'/help'}>
          <IconButton
            color={'#242424'}
            alignSelf={'flex-end'}
            aria-label="Ayuda"
            icon={<QuestionIcon />}
            bgColor={'transparent'}
          />
        </Link>
      </Box>
      <Grid minH="100vh" alignContent="center">
        <Center>
          <Image
            src={logo}
            alt="RouteForecast logo"
            boxSize={['150px', '170px', '200px']}
            objectFit="scale-down"
          />
        </Center>
        <Heading
          as="h1"
          fontSize={{ base: '45px', md: '60px', lg: '65px' }}
          fontWeight={360}
        >
          Route Forecast
          <Badge verticalAlign={'baseline'} colorScheme="purple">
            Beta
          </Badge>
        </Heading>
        <Heading
          as="h2"
          fontSize={{ base: '13px', md: '22px', lg: '28px' }}
          fontWeight={250}
        >
          Conoce el Clima en tu Ruta
        </Heading>
        <Center pt={5} pb={5}>
          <VStack maxW={'100%'}>
            <HStack spacing={5}>
              <a href="#quienes-somos">
                <Button
                  colorScheme="green"
                  fontFamily="Roboto"
                  fontWeight="normal"
                >
                  ¿Quiénes Somos?
                </Button>
              </a>
              <Link as={ReactRouterLink} to={'/map'}>
                <Button
                  colorScheme="green"
                  variant="outline"
                  fontFamily="Roboto"
                  fontWeight="normal"
                  isDisabled={false}
                >
                  Planear Ruta
                </Button>
              </Link>
            </HStack>
            <HStack marginTop={10}>
              <Text textStyle={'poweredBy'}>Powered by</Text>
              <Image src={aemetLogo} boxSize={70} objectFit="scale-down" />
            </HStack>
          </VStack>
        </Center>
      </Grid>
    </header>
  );
}
