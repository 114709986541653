import { Box, Center, CloseButton, Img, Text, VStack } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';

export default function WeatherHeader(props) {
  const {
    localityName,
    stateName,
    weatherCode,
    weatherDescription,
    temperature,
  } = props;

  const [image, setImage] = useState('');

  useEffect(() => {
    if (weatherCode)
      (function (imageName) {
        try {
          import(`../../../../../assets/weathericons/${imageName}_g.png`).then(
            image => setImage(image.default)
          );
        } catch (e) {
          console.error(e);
        }
      })(weatherCode);
  }, [weatherCode]);

  return (
    <Box textAlign={'center'}>
      <Text textStyle={'weatherCardProvincia'}>{stateName}</Text>
      <Text textStyle={'weatherCardLocalidad'} color={'#242424'}>
        {localityName}
      </Text>
      <Center>
        {image && <Img w={'50px'} h={'50px'} src={image} alt="Weather Icon" />}
      </Center>

      <Text textStyle={'paragraphBody'}>{weatherDescription}</Text>
      <Text textStyle={'paragraphBody'}>{temperature}ºC</Text>
    </Box>
  );
}
