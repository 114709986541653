import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { ReactComponent as SVGImage } from '../../../../../assets/svg/traveling_white.svg';
import ResponsiveSVG from '../../../../Misc/ResponsiveSVG';

export default function Enjoy() {
  return (
    <Box textAlign={'center'} bgColor={'#38A169'} p={0} m={0}>
      <Text textStyle={'artWorkWhite'} pt={50}>
        y disfruta.
      </Text>
      <ResponsiveSVG
        SVGImage={SVGImage}
        widthArray={['70%', null, null, '23%']}
        mt={50}
        ml={['15%', null, null, '38.5%']}
      />
    </Box>
  );
}
