import { Box, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import TimelineItem from './TimelineItem/TImelineItem';
import { useEffect, useState } from 'react';

import './WeatherTimeline.scss';

export default function WeatherTimeline(props) {
  const [mergedData, setMergedData] = useState([]);
  const { data, setMainConditions, selectedHour, setSelectedHour } = props;

  useEffect(() => {
    if (data !== null) {
      setMergedData(data[0].hours.concat(data[1].hours));
    }
  }, [data]);

  useEffect(() => {
    if (mergedData && selectedHour !== null)
      setMainConditions(mergedData[selectedHour]);
  }, [selectedHour, mergedData, setMainConditions]);

  return (
    <HStack
      w="90%"
      p={3}
      className="horizontal-scrollbar"
      gap={3}
      bgColor="#d9d9d926"
      borderRadius={5}
      mt={10}
    >
      {data &&
        mergedData.map((item, index) => (
          <TimelineItem
            key={`${index}_${item.period}_${item.weatherCode}`}
            period={item.period}
            weatherCode={item.weatherCode}
            temperature={item.temperature}
            handleOnClick={() => {
              setSelectedHour(index);
            }}
            selected={selectedHour === index}
          />
        ))}
    </HStack>
  );
}
