import React, { useEffect, useState } from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import routes from './config/routes';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-YMJYGJM7BD');

const theme = extendTheme({
  textStyles: {
    poweredBy: {
      color: '#9c9c9c',
      fontSize: ['12', '16'],
      fontWeight: 100,
    },
    artWorkWhite: {
      color: '#fafafa',
      fontSize: ['40', '46', '70'],
      fontWeight: 'normal',
    },
    paragraphHeading: {
      fontSize: ['33', null, null, '40'],
      fontWeight: 'medium',
    },
    paragraphSubHeading: {
      fontSize: ['20', null, null, '23'],
      fontWeight: '350',
    },
    paragraphBody: {
      fontSize: ['15', null, null, '15'],
      fontWeight: 300,
    },
    weatherCardHeading: {
      fontSize: ['14', null, null, null, '20'],
      fontWeight: 200,
    },
    weatherCardData: {
      fontSize: ['33', null, null, '35'],
      fontWeight: '300',
    },
    weatherCardLocalidad: {
      fontSize: ['33', null, null, '28'],

      fontWeight: '300',
    },
    weatherCardProvincia: {
      fontSize: ['24', null, null, '24'],
      fontWeight: '300',
    },
    weatherCardWeatherDescription: {
      fontSize: ['24', null, null, '24'],
      fontWeight: '300',
    },
    timelineItem: {
      fontSize: ['15', null, null, '15'],
      fontWeight: 300,
    },
    loadingText: {
      fontSize: ['20', null, null, '23'],
      fontWeight: 'bold',
    },
  },
  fonts: {
    heading: 'Roboto',
    body: 'Roboto',
  },
  fontWeight: {
    bold: '250',
  },
});

function App() {
  const [appOpenTime, setAppOpenTime] = useState(Date.now());

  // Function to handle pathname changes
  const handlePathnameChange = () => {
    const currentPath = window.location.pathname;
    console.log(currentPath);
    ReactGA.send({ hitType: 'pageview', page: currentPath });
  };

  // Send 'opened' event when the app is opened
  useEffect(() => {
    ReactGA.event({
      category: 'App Interaction',
      action: 'Opened',
      label: 'App Opened',
      value: 1,
      nonInteraction: true,
      transport: 'xhr',
    });
  }, []);

  // Send 'closed' event and calculate duration when the app is closed
  useEffect(() => {
    const handleAppClose = () => {
      const appCloseTime = Date.now();
      const durationInSeconds = Math.round((appCloseTime - appOpenTime) / 1000);

      ReactGA.event({
        category: 'App Interaction',
        action: 'Closed',
        label: 'App Closed',
        value: durationInSeconds,
        nonInteraction: true,
        transport: 'xhr',
      });
    };

    // Listen for the 'beforeunload' event when the user closes the app or tab
    window.addEventListener('beforeunload', handleAppClose);

    return () => {
      // Cleanup the event listener
      window.removeEventListener('beforeunload', handleAppClose);
    };
  }, [appOpenTime]);

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <route.layout>
                  <route.component />
                </route.layout>
              }
            />
          ))}
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
