import { Box, Center, Image, Img, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

export default function TimelineItem(props) {
  const [image, setImage] = useState(null);
  const { weatherCode, period, temperature, handleOnClick, selected } = props;

  useEffect(() => {
    if (weatherCode)
      (function (imageName) {
        try {
          import(
            `../../../../../../assets/weathericons/${imageName}_g.png`
          ).then(image => setImage(image.default));
        } catch (e) {
          console.error(e);
        }
      })(weatherCode);
  }, [weatherCode]);
  return (
    <Center
      minW={'50px'}
      onClick={handleOnClick}
      border={selected ? '1px solid #d9d9d990' : '0px'}
      borderRadius={5}
    >
      <VStack>
        <Text textStyle={'timelineItem'}>{period}</Text>
        {image && <Img w={'100%'} src={image} alt="Weather Icon" />}
        <Text textStyle={'timelineItem'}>{temperature}º</Text>
      </VStack>
    </Center>
  );
}
