const codigosProvincias = {
  '02': {
    es: 'Albacete',
    en: 'Albacete',
  },
  '03': {
    es: 'Alicante/Alacant',
    en: 'Alicante/Alacant',
  },
  '04': {
    es: 'Almería',
    en: 'Almeria',
  },
  '01': {
    es: 'Araba/Álava',
    en: 'Araba/Álava',
  },
  33: {
    es: 'Asturias',
    en: 'Asturias',
  },
  '05': {
    es: 'Ávila',
    en: 'Avila',
  },
  '06': {
    es: 'Badajoz',
    en: 'Badajoz',
  },
  '07': {
    es: 'Illes Balears/Islas Baleares',
    en: 'Balearic Islands',
  },
  '08': {
    es: 'Barcelona',
    en: 'Barcelona',
  },
  48: {
    es: 'Bizkaia',
    en: 'Biscay',
  },
  '09': {
    es: 'Burgos',
    en: 'Burgos',
  },
  10: {
    es: 'Cáceres',
    en: 'Caceres',
  },
  11: {
    es: 'Cádiz',
    en: 'Cadiz',
  },
  39: {
    es: 'Cantabria',
    en: 'Cantabria',
  },
  12: {
    es: 'Castellón/Castelló',
    en: 'Castellon/Castelló',
  },
  13: {
    es: 'Ciudad Real',
    en: 'Ciudad Real',
  },
  14: {
    es: 'Córdoba',
    en: 'Cordoba',
  },
  15: {
    es: 'A Coruña',
    en: 'La Coruña',
  },
  16: {
    es: 'Cuenca',
    en: 'Cuenca',
  },
  20: {
    es: 'Gipuzkoa',
    en: 'Gipuzkoa',
  },
  17: {
    es: 'Girona',
    en: 'Girona',
  },
  18: {
    es: 'Granada',
    en: 'Granada',
  },
  19: {
    es: 'Guadalajara',
    en: 'Guadalajara',
  },
  21: {
    es: 'Huelva',
    en: 'Huelva',
  },
  22: {
    es: 'Huesca',
    en: 'Huesca',
  },
  23: {
    es: 'Jaén',
    en: 'Jaen',
  },
  24: {
    es: 'León',
    en: 'Leon',
  },
  25: {
    es: 'Lleida',
    en: 'Lleida',
  },
  27: {
    es: 'Lugo',
    en: 'Lugo',
  },
  28: {
    es: 'Madrid',
    en: 'Madrid',
  },
  29: {
    es: 'Málaga',
    en: 'Malaga',
  },
  30: {
    es: 'Murcia',
    en: 'Murcia',
  },
  31: {
    es: 'Navarra',
    en: 'Navarre',
  },
  32: {
    es: 'Ourense',
    en: 'Ourense',
  },
  34: {
    es: 'Palencia',
    en: 'Palencia',
  },
  35: {
    es: 'Las Palmas',
    en: 'Las Palmas',
  },
  36: {
    es: 'Pontevedra',
    en: 'Pontevedra',
  },
  26: {
    es: 'La Rioja',
    en: 'La Rioja',
  },
  37: {
    es: 'Salamanca',
    en: 'Salamanca',
  },
  38: {
    es: 'Santa Cruz de Tenerife',
    en: 'Santa Cruz de Tenerife',
  },
  40: {
    es: 'Segovia',
    en: 'Segovia',
  },
  41: {
    es: 'Sevilla',
    en: 'Seville',
  },
  42: {
    es: 'Soria',
    en: 'Soria',
  },
  43: {
    es: 'Tarragona',
    en: 'Tarragona',
  },
  44: {
    es: 'Teruel',
    en: 'Teruel',
  },
  45: {
    es: 'Toledo',
    en: 'Toledo',
  },
  46: {
    es: 'Valencia/València',
    en: 'Valencia',
  },
  47: {
    es: 'Valladolid',
    en: 'Valladolid',
  },
  49: {
    es: 'Zamora',
    en: 'Zamora',
  },
  50: {
    es: 'Zaragoza',
    en: 'Zaragoza',
  },
  51: {
    es: 'Ceuta',
    en: 'Ceuta',
  },
  52: {
    es: 'Melilla',
    en: 'Melilla',
  },
};

export default codigosProvincias;
