import { Box, Card, Center, Text } from '@chakra-ui/react';
import WindArrow from './WindArrow/WindArrow';
import React from 'react';

export default function WeatherCard(props) {
  const { title, data } = props;

  return (
    <Box
      w={['48%', null, null, null, '48%']}
      padding={'5px'}
      m={0}
      h={['200px']}
      bgColor={'#d9d9d926'}
      display="flex" // Use flex display
      flexDirection="column"
      justifyContent="center" // Center vertically
      alignItems="center" // Center horizontally
      borderRadius={5}
    >
      <Text
        textStyle={'weatherCardHeading'}
        textTransform={'uppercase'}
        position={'relative'}
        alignSelf={'flex-start'}
      >
        {title}
      </Text>
      {data === null ? (
        <Text>No data available</Text>
      ) : typeof data === 'object' ? (
        <>
          {data.windDirection || data.windGusts || data.windSpeed ? (
            <Center
              display={'flex'}
              flexDirection={'column'}
              alignContent={'center'}
              h={'100%'}
            >
              <Text textStyle={'weatherCardHeading'}>{data.windDirection}</Text>

              <Box
                h={'50px'}
                w={'50px'}
                borderRadius={'50%'}
                border={'1px #000 solid'}
                display="flex"
                justifyContent="center" // Center horizontally
                alignItems="center" // Center vertically
              >
                <WindArrow windDirection={data.windDirection} />
              </Box>
              <Text textStyle={'weatherCardHeading'}>
                {data.windSpeed} km/h
              </Text>
              {/* <Text textStyle={'weatherCardHeading'}>
                {Math.round(data.windSpeed * 0.5399568)} kn
              </Text> */}
              <Text textStyle={'paragraphBody'}>
                Rachas {data.windGusts} km/h
                {/* {Math.round(data.windSpeed * 0.5399568)} kn) */}
              </Text>
            </Center>
          ) : (
            <Text>Other data: {JSON.stringify(data)}</Text>
          )}
        </>
      ) : (
        <Center
          display={'flex'}
          flexDirection={'column'}
          alignContent={'center'}
          h={'100%'}
        >
          <Text textStyle={'weatherCardData'}>{data}</Text>
        </Center>
      )}
    </Box>
  );
}
