import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  Box,
  FormControl,
  FormLabel,
  FormHelperText,
  InputGroup,
  Input,
  InputLeftElement,
  HStack,
  Radio,
  RadioGroup,
  Button,
  Center,
  Card,
  Text,
  VStack,
  IconButton,
  useToast,
  Select,
  Checkbox,
  InputRightAddon,
  InputRightElement,
} from '@chakra-ui/react';
import {
  ArrowForwardIcon,
  ArrowBackIcon,
  AddIcon,
  DeleteIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@chakra-ui/icons';

import './RouteForm.scss';

export default function RouteForm({
  TravelMode,
  directionsCallback,
  handleSubmit,
  onTravelModeChange,
  isVisible,
  alreadyCalculated,
  errorMessage,
  handleDeleteWaypointFromDirectionsService,
  setDirectionsServiceOptions,
  directionsServiceOptions,
  setAlreadyCalculated,
  SearchBox,
  ...props
}) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const toast = useToast();

  const handleOptionChange = option => {
    setAlreadyCalculated(false);
    let updatedOptions = [...selectedOptions];

    if (updatedOptions.includes(option)) {
      updatedOptions = updatedOptions.filter(item => item !== option);
    } else {
      updatedOptions = [...updatedOptions, option];
    }

    // Update directionsServiceOptions with the selected options
    const updatedDirectionsServiceOptions = {
      ...directionsServiceOptions,
      avoidFerries: updatedOptions.includes('avoidFerries'),
      avoidHighways: updatedOptions.includes('avoidHighways'),
      avoidTolls: updatedOptions.includes('avoidTolls'),
    };

    setSelectedOptions(updatedOptions);
    setDirectionsServiceOptions(updatedDirectionsServiceOptions);
  };

  const onFormInputTextChange = e => {
    setAlreadyCalculated(false);
    e.preventDefault();

    if (e.target.name.includes('waypoint')) {
      setDirectionsServiceOptions({
        ...directionsServiceOptions,
        waypoints: {
          ...directionsServiceOptions.waypoints,
          [e.target.name]: e.target.value,
        },
      });
    } else {
      setDirectionsServiceOptions({
        ...directionsServiceOptions,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onFormWaypointTextChange = (e, waypointId) => {
    setAlreadyCalculated(false);

    const updatedWaypoints = directionsServiceOptions.waypoints.map(
      waypoint => {
        if (waypoint.id === waypointId) {
          return { ...waypoint, value: e.target.value };
        }
        return waypoint;
      }
    );

    setDirectionsServiceOptions({
      ...directionsServiceOptions,
      waypoints: updatedWaypoints,
    });
  };

  const handleAddWaypoint = () => {
    if (directionsServiceOptions.waypoints.length < 5) {
      const newWaypoint = {
        id: uuidv4(),
        value: '',
      };

      setDirectionsServiceOptions({
        ...directionsServiceOptions,
        waypoints: [...directionsServiceOptions.waypoints, newWaypoint],
      });
    } else {
      toast({
        title: 'Error.',
        description: 'Número máximo de waypoints alcanzado.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteWaypoint = waypointId => {
    const updatedWaypoints = directionsServiceOptions.waypoints.filter(
      waypoint => waypoint.id !== waypointId
    );

    setDirectionsServiceOptions({
      ...directionsServiceOptions,
      waypoints: updatedWaypoints,
    });
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Card
      className="route-form-card"
      position={['absolute', null, null, null, 'absolute']}
      zIndex={!isVisible ? -1 : 6}
      bgColor={'#fff'}
      p={3}
      m={[0, 0, 0, 0, 2]}
      w={['100%', null, null, null, '350px']}
      maxW={['100%', null, null, null, '350px']}
      bottom={0}
      minH={['50vh', null, null, null, 'auto']}
      maxH={[
        '100%',
        null,
        null,
        null,
        window.innerHeight - window.innerHeight / 10,
      ]}
      overflowY={'scroll'}
      borderRadius={10}
      sx={[
        {},
        null,
        null,
        null,
        { top: [3, null, null, null, 3], left: [3, null, null, null, 3] },
      ]}
      display={'flex'}
      flexDirection={['column', null, null, null, 'column']}
      justifyContent={['flex-start', null, null, null, 'space-evenly']}
    >
      <Center>
        <Box
          mb={2}
          w={'30px'}
          h={'5px'}
          bgColor={'#aeaeae'}
          borderRadius={10}
          alignSelf={'flex-start'}
        ></Box>
      </Center>
      <Text textStyle={'paragraphSubHeading'} textAlign={'center'}>
        Información de la ruta
      </Text>
      <form onSubmit={directionsCallback}>
        <VStack>
          <FormControl>
            <FormLabel mb={0}>Origen</FormLabel>

            <InputGroup display="flex" alignItems="center" mt={0} mb={3}>
              <InputLeftElement>
                <ArrowForwardIcon />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Origen"
                name="origin"
                onChange={onFormInputTextChange}
                value={directionsServiceOptions.origin}
              />
              <InputRightElement>
                <IconButton
                  backgroundColor={'#fafafa'}
                  icon={<AddIcon />}
                  onClick={handleAddWaypoint}
                />
              </InputRightElement>
            </InputGroup>

            {directionsServiceOptions.waypoints.length > 0 && (
              <FormLabel>Waypoints</FormLabel>
            )}
            {directionsServiceOptions.waypoints.map(waypoint => {
              return (
                <Box
                  key={waypoint.id}
                  display="flex"
                  mt={3}
                  flexDirection="row" // Use "row" to align horizontally
                  alignItems="flex-end"
                  justifyContent="space-around"
                  width="100%"
                >
                  <IconButton
                    icon={<DeleteIcon />}
                    onClick={() => handleDeleteWaypoint(waypoint.id)}
                    alignSelf={'flex-start'}
                  />
                  <InputGroup
                    display="flex"
                    flexDirection="column" // Use "column" to stack the icon button and input vertically
                    alignItems="flex-end"
                    width="80%"
                  >
                    <InputLeftElement>
                      <ArrowForwardIcon />
                    </InputLeftElement>
                    <Input
                      alignSelf="flex-end"
                      type="text"
                      placeholder="Waypoint"
                      name={`waypoint-${waypoint.id}`}
                      value={waypoint.value}
                      onChange={e => onFormWaypointTextChange(e, waypoint.id)}
                    />
                  </InputGroup>
                </Box>
              );
            })}
          </FormControl>
          <FormControl>
            <FormLabel mb={0}>Destino</FormLabel>
            <InputGroup>
              <InputLeftElement>
                <ArrowBackIcon />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Destino"
                name="destination"
                value={directionsServiceOptions.destination}
                onChange={onFormInputTextChange}
              />
            </InputGroup>
          </FormControl>
          <FormControl as="fieldset">
            <FormLabel as="legend">Medio de transporte</FormLabel>
            <RadioGroup
              defaultValue={TravelMode.DRIVING}
              name="travelMode"
              onChange={onTravelModeChange}
            >
              <HStack spacing="24px">
                <Radio value={TravelMode.DRIVING}>Coche</Radio>
                <Radio value={TravelMode.BICYCLING}>Bicicleta</Radio>
                <Radio value={TravelMode.WALKING}>A pie</Radio>
              </HStack>
            </RadioGroup>
            <FormHelperText>Selecciona tu medio de transporte.</FormHelperText>
          </FormControl>
          <Box
            display={'flex'}
            w={'100%'}
            flexDir={'column'}
            alignItems={'flex-start'}
          >
            <FormLabel as="legend" onClick={toggleDropdown} cursor="pointer">
              {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />} Opciones de
              ruta
            </FormLabel>
            {isOpen && (
              <VStack
                alignItems="flex-start"
                justifyContent="flex-start"
                border="1px solid #ccc"
                p="2"
                borderRadius="md"
                w={'100%'}
              >
                <Checkbox
                  isChecked={selectedOptions.includes('avoidFerries')}
                  onChange={() => handleOptionChange('avoidFerries')}
                >
                  Evitar ferris
                </Checkbox>
                <Checkbox
                  isChecked={selectedOptions.includes('avoidHighways')}
                  onChange={() => handleOptionChange('avoidHighways')}
                >
                  Evitar autopistas
                </Checkbox>
                <Checkbox
                  isChecked={selectedOptions.includes('avoidTolls')}
                  onChange={() => handleOptionChange('avoidTolls')}
                >
                  Evitar peajes
                </Checkbox>
              </VStack>
            )}
          </Box>

          <Text color={'red.400'}>{errorMessage}</Text>
          <Center mt={5}>
            <Button
              colorScheme="green"
              variant={'outline'}
              onClick={handleSubmit}
              isDisabled={alreadyCalculated}
            >
              Calcular Ruta
            </Button>
          </Center>
        </VStack>
      </form>
    </Card>
  );
}
