import React, { useState, useEffect } from 'react';
import { Box, Text, VStack } from '@chakra-ui/react';
import LottieAnimation from '../LottieAnimation';

import './LoadingMap.scss';

export default function LoadingMap(props) {
  const animationData = require('../../../assets/animations/route_loader.json');

  const [sentences, setSentences] = useState(
    shuffleArray([
      'Hablando con los dioses del clima...',
      'Dando una vuelta por el cielo...',
      'Midiendo la temperatura...',
      'Calculando pronósticos...',
      'Monitoreando el viento...',
      'Siguiendo las nubes...',
      '¡Que venga el meteorólogo!',
      'El tiempo está cambiando...',
      'La naturaleza está en movimiento...',
      'Explorando el clima...',
      'Preparando la información...',
      'Analizando patrones climáticos...',
      'Observando el cielo...',
      'Recopilando información...',
      'Haciendo magia meteorológica...',
      'Verificando la humedad...',
    ])
  );
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const [showText, setShowText] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      // Toggle the text visibility and update the sentence index
      setShowText(prevShowText => !prevShowText);
      if (currentSentenceIndex === sentences.length - 1) {
        setSentences(shuffleArray(sentences));
      }
      setCurrentSentenceIndex(prevIndex =>
        prevIndex < sentences.length - 1 ? prevIndex + 1 : 0
      );
    }, 3000); // Change the sentence every 2 seconds (adjust as needed)

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handlePopInOut = () => {
    // Delay before fading out
    setTimeout(() => {
      setShowText(false);
    }, 3000); // Delay for 1 second before fading out
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100vh"
      zIndex={10}
      background="rgba(0, 0, 0, 0.4)"
      userSelect={'none'}
    >
      <VStack>
        <Box className="lottie-animation-fixed no-select">
          <LottieAnimation animationData={animationData} />
        </Box>

        <Text
          textStyle={'paragraphSubHeading'}
          color={'#fafafa'}
          key={sentences[currentSentenceIndex]}
          onAnimationEnd={handlePopInOut}
          className="pop-in-out-animation no-select"
        >
          {sentences[currentSentenceIndex]}
        </Text>
      </VStack>
    </Box>
  );
}

function shuffleArray(array) {
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
}
