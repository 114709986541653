import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Button,
  CloseButton,
  HStack,
  Text,
  VStack,
  Image,
  IconButton,
  useMediaQuery,
} from '@chakra-ui/react';
import WeatherHeader from './WeatherHeader';
import WeatherTimeline from './WeatherTimeline';
import WeatherCard from './WeatherCard';
import {
  filterHoursAhead,
  separateArrays,
} from '../../../../util/WeatherFilter';
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  CloseIcon,
  UpDownIcon,
} from '@chakra-ui/icons';
const aemetLogo = require('../../../../assets/png/logoAemet.png');

export default function WeatherInfo(props) {
  const [mainConditions, setMainConditions] = useState({
    weatherCode: null, //estadoCielo value
    weatherDescription: null, //estadoCielo descripcion
    temperature: null, //temperatura
    windDirection: null, //vientoyrachmax 1er array direccion
    windSpeed: null, //vientoyrachmax 1er array velocidad
    windGusts: null, //vientoyrachmax 2do array value
    feelsLike: null, // sensTermica value
    humidity: null, // humedadRelativa value
    precipitation: null, // precipitacion value
    stormProbability: null,
    snowProbability: null,
  });
  const [isExpanded, setIsExpanded] = useState(false);

  const { data, setIndexToRender, maxIndex, selectedHour, setSelectedHour } =
    props;
  useEffect(() => {
    if (data) {
      console.log(data);
      setMainConditions(data.weatherForecast[0].hours[0]);
    }
  }, [data]);

  const [isLargerThanMobile] = useMediaQuery('(min-width: 768px)');
  const toggleExpand = () => {
    setIsExpanded(prevState => !prevState);
  };
  return (
    <Box
      borderTopRadius={15}
      position={['absolute']}
      w={['100%', null, null, '30%', '30%']}
      right={[null, null, null, isLargerThanMobile ? 0 : null]}
      zIndex={5}
      maxH={
        isExpanded
          ? window.innerHeight
          : [window.innerHeight / 1.5, null, null, window.innerHeight]
      }
      overflowY={'scroll'}
      bottom={0}
    >
      {data && (
        <Card w={'100%'} borderTopRadius={15}>
          <Box
            p={3}
            w={'100%'}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={isLargerThanMobile ? 'center' : 'space-between'}
            alignItems={'center'}
          >
            <IconButton
              padding={0}
              background={'transparent'}
              icon={<CloseIcon />}
              onClick={() => {
                setIndexToRender(null);
              }}
              display={isLargerThanMobile ? 'none' : 'block'}
              m={4}
            />
            <div>
              <VStack
                w={'100%'}
                gap={0}
                textAlign={'center'}
                justifyContent={'center'}
              >
                <Text textStyle={'poweredBy'}>Datos proporcionados por</Text>
                <Image src={aemetLogo} boxSize={70} objectFit="scale-down" />
              </VStack>
            </div>

            <IconButton
              padding={0}
              background={'transparent'}
              icon={<UpDownIcon />}
              onClick={toggleExpand}
              display={isLargerThanMobile ? 'none' : 'block'}
              m={4}
            />
          </Box>
          <VStack w={'100%'}>
            <HStack
              w={'90%'}
              display={'flex'}
              flexDirection={'row'}
              alignContent={'center'}
              justifyContent={'space-between'}
            >
              <IconButton
                icon={<ArrowBackIcon />}
                onClick={() => {
                  setIndexToRender(prevIndex => {
                    if (prevIndex - 1 >= 0) return prevIndex - 1;
                    else return prevIndex;
                  });
                }}
              />

              <WeatherHeader
                localityName={data?.localityName}
                stateName={data?.localityProvince}
                weatherCode={mainConditions?.weatherCode}
                weatherDescription={mainConditions?.weatherDescription}
                temperature={mainConditions?.temperature}
              />
              <IconButton
                icon={<ArrowForwardIcon />}
                onClick={() => {
                  setIndexToRender(prevIndex => {
                    if (prevIndex + 1 <= maxIndex) return prevIndex + 1;
                    else return prevIndex;
                  });
                }}
              />
            </HStack>

            <WeatherTimeline
              data={data.weatherForecast}
              setMainConditions={setMainConditions}
              selectedHour={selectedHour}
              setSelectedHour={setSelectedHour}
            />
            <HStack
              w={'90%'}
              mt={10}
              alignContent={'center'}
              justifyContent={'space-evenly'}
              gap={1}
            >
              <WeatherCard
                title="Viento"
                data={{
                  windSpeed: mainConditions?.windSpeed,
                  windDirection: mainConditions?.windDirection,
                  windGusts: mainConditions?.windGusts,
                }}
              />
              <WeatherCard
                title="Precipitaciones"
                data={`${mainConditions?.precipitation}mm`}
              />
            </HStack>
            <HStack
              w={'90%'}
              alignContent={'center'}
              justifyContent={'space-evenly'}
              gap={1}
            >
              <WeatherCard
                title="Humedad"
                data={`${mainConditions?.humidity}%`}
              />
              <WeatherCard
                title="Sensación térmica"
                data={`${mainConditions?.feelsLike}ºC`}
              />
            </HStack>
            <HStack
              w={'90%'}
              alignContent={'center'}
              justifyContent={'space-evenly'}
              gap={1}
            >
              <WeatherCard
                title="Prob. Tormenta"
                data={`${mainConditions?.stormProbability?.value}%`}
              />
            </HStack>
          </VStack>
        </Card>
      )}
    </Box>
  );
}
