import React, { useState } from 'react';
import {
  Center,
  Box,
  Text,
  VStack,
  Button,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalContent,
  Link,
} from '@chakra-ui/react';
import ResponsiveSVG from '../../../Misc/ResponsiveSVG/ResponsiveSVG';
import { ReactComponent as SVGTrip } from '../../../../assets/svg/trip.svg';
import { ReactComponent as SVGPlan } from '../../../../assets/svg/team_working.svg';
import BetaInscription from '../../BetaInscription/BetaInscription';
import { Link as ReactRouterLink } from 'react-router-dom';

export default function WhoWeAre() {
  const [modalVisible, setModalVisible] = useState(false);

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };
  return (
    <Center>
      <Box
        w={['92.5%', null, null, null, '50%']}
        textAlign={'left'}
        id="quienes-somos"
      >
        <Text textStyle={'paragraphHeading'} mt={10} mb={0}>
          Descubre la Pasión por el Clima y la Aventura en RouteForecast
        </Text>
        <Text textStyle={'paragraphSubHeading'} pb={45}>
          Pronósticos Meteorológicos Personalizados para Tu Viaje Perfecto
        </Text>
        <ResponsiveSVG
          SVGImage={SVGTrip}
          widthArray={['70%', null, null, '40%']}
          ml={['15%', null, null, '30%']}
        />
        <Text textStyle={'paragraphBody'} pt={20}>
          ¡Bienvenidos a <b>RouteForecast</b>, donde la pasión por el clima y la
          emoción de la aventura se unen para brindarte una experiencia única!
          No somos solo una plataforma de pronósticos meteorológicos; somos tus
          aliados en la búsqueda de aventuras inolvidables. Nuestro objetivo es
          proporcionarte información climática precisa y personalizada para que
          tu viaje esté lleno de confianza y emoción, independientemente de los
          desafíos que el clima pueda presentar.
        </Text>
        <Text textStyle={'paragraphSubHeading'} pt={5} fontWeight={400}>
          Nuestra Historia
        </Text>
        <Text textStyle={'paragraphBody'} pt={2}>
          Desde nuestros modestos inicios, hemos estado en la esencia misma de
          cada aventura al aire libre. Con un equipo apasionado por la
          tecnología y una determinación implacable, nuestro objetivo es
          convertir cada travesía en algo excepcional. Entendemos a fondo cómo
          el clima puede afectar la vivencia, y nuestra misión es otorgarte el
          control necesario para que puedas saborear cada instante sin
          inquietudes.
        </Text>
        <Text textStyle={'paragraphSubHeading'} pt={5} fontWeight={400}>
          Cómo Moldeamos Tu Viaje
        </Text>
        <ResponsiveSVG
          SVGImage={SVGPlan}
          widthArray={['70%', null, null, '50%']}
          ml={['15%', null, null, '25%']}
          mt={10}
          mb={10}
        />
        <ul>
          <Box
            w={['90%', null, null, '95%']}
            textAlign={'left'}
            ml={['5%', null, null, '2.5%']}
          >
            <li>
              <Text textStyle={'paragraphBody'} pt={[2, 2, 2, 10]}>
                <b>Pronósticos Meteorológicos Personalizados:</b> Creamos
                pronósticos meticulosos para tu ruta específica. Ya sea que te
                enfrentes a una pendiente empinada en tu bicicleta de montaña o
                a la carretera abierta en tu motocicleta, tendrás la información
                que necesitas en cada giro.
              </Text>
            </li>
            <li>
              <Text
                textStyle={'paragraphBody'}
                pt={[2, 2, 2, 10]}
                pb={[2, 2, 2, 10]}
              >
                <b>Planificación Informada:</b> Nuestros mapas interactivos y
                datos climáticos históricos te permiten visualizar la ruta y las
                condiciones pasadas. Planifica tus paradas y adapta tu viaje
                según el clima.
              </Text>
            </li>
          </Box>
        </ul>
        <Box id="beta">
          <Text textStyle={'paragraphSubHeading'} pt={5} fontWeight={400}>
            Únete a la Aventura Ahora
          </Text>
          <Text textStyle={'paragraphBody'} pt={2}>
            En RouteForecast, estamos emocionados de ser tus guías en tus
            aventuras. El clima es solo otro elemento emocionante de tu viaje, y
            estamos aquí para asegurarnos de que estés preparado para todo.
            Únete a nosotros en esta búsqueda, donde cada paso es una
            oportunidad para descubrir, aprender y conectarte con la naturaleza.
          </Text>
          <Center m={10}>
            <Link as={ReactRouterLink} to={'/map'}>
              <Button
                colorScheme="green"
                fontFamily="Roboto"
                fontWeight="normal"
                isDisabled={false}
              >
                Planear Ruta
              </Button>
            </Link>
          </Center>
        </Box>
      </Box>
      <Modal isOpen={modalVisible} onClose={closeModal} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Acceso Anticipado</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <BetaInscription closeModal={closeModal} />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" onClick={closeModal}>
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Center>
  );
}
