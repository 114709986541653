import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import Header from '../components/Web/LandingPage/Header';
import Create from '../components/Web/LandingPage/ArtWorks/Create/Create';
import Share from '../components/Web/LandingPage/ArtWorks/Share/Share';
import Enjoy from '../components/Web/LandingPage/ArtWorks/Enjoy/Enjoy';
import WhoWeAre from '../components/Web/LandingPage/WhoWeAre/WhoWeAre';
import ReactGA from 'react-ga4';
import Footer from '../components/Web/Footer/Footer';
import LayoutBasic from '../layouts/LayoutBasic';
import { Helmet } from 'react-helmet';

ReactGA.initialize('G-YMJYGJM7BD');

export default function Landing() {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/' });
  }, []);

  return (
    <LayoutBasic>
      <Helmet>
        <title>
          Route Forecast - Pronósticos del tiempo precisos para tus rutas
        </title>
        <meta
          name="description"
          content="Route Forecast proporciona pronósticos del tiempo precisos para tus rutas. Planea mejor tus viajes con nuestras actualizaciones del tiempo confiables y en tiempo real."
        />
      </Helmet>
      <Box textAlign="center">
        <Header />
        <aside>
          <Create />
          <Share />
          <Enjoy />
        </aside>
        <section>
          <WhoWeAre />
        </section>
        <Footer />
      </Box>
    </LayoutBasic>
  );
}
