import React from 'react';
import Lottie from 'react-lottie';

export default function LottieAnimation(props) {
  // Lottie options
  const defaultOptions = {
    loop: true, // Set loop to true if you want the animation to loop
    autoplay: true, // Set autoplay to true if you want the animation to start when the component mounts
    animationData: props.animationData, // Pass the animation data from props
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice', // Adjust this setting as needed
    },
  };

  return (
    <div>
      <Lottie
        options={defaultOptions}
        height={props.height || 200} // Set the height of the animation (you can adjust or use a prop)
        width={props.width || 200} // Set the width of the animation (you can adjust or use a prop)
        onPointerDown={() => {
          // Do nothing to prevent the animation from pausing
        }}
      />
    </div>
  );
}
