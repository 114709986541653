import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import MapFrame from '../components/Web/Map/MapFrame/MapFrame';
import WeatherInfo from '../components/Web/Map/WeatherInfo/';
import { useToast } from '@chakra-ui/react';
import LoadingMap from '../components/Misc/LoadingMap/LoadingMap';
import RouteApi from '../api/RouteApi';
import ReactGA from 'react-ga4';
import LayoutBasic from '../layouts/LayoutBasic';
ReactGA.initialize('G-YMJYGJM7BD');

export default function Map() {
  const [absoluteLocalities, setAbsoluteLocalities] = useState([]);
  const [localitiesAndWeather, setLocalitiesAndWeather] = useState([]);
  const [selectedHour, setSelectedHour] = useState(0);
  const [indexToRender, setIndexToRender] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [apiData, setApiData] = useState(null);
  const { routeId } = useParams();
  const toast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (absoluteLocalities.length > 0) {
          // Create a set to keep track of unique localityCode values
          const uniqueLocalityCodes = new Set();

          // Filter out duplicates based on localityCode
          const uniqueLocalities = absoluteLocalities.filter(item => {
            if (!uniqueLocalityCodes.has(item.localityCode)) {
              uniqueLocalityCodes.add(item.localityCode);
              return true;
            }
            return false;
          });

          const data = await RouteApi.getRouteWeather(uniqueLocalities);

          if (data.definedLocalities) {
            const filteredLocalities = data.definedLocalities.forEach(
              locality => {
                const currentTime = new Date().getHours();
                locality.weatherForecast[0].hours =
                  locality.weatherForecast[0].hours.filter(entry => {
                    return parseInt(entry.period) >= currentTime;
                  });
              }
            );
            setLocalitiesAndWeather(data.definedLocalities);

            if (data.message) {
              toast({
                title: 'Lo sentimos.',
                description: `No se pudo cargar la información meteorológica para ${data.diff} localidades debido a una congestión en el servicio. Por favor, inténtelo nuevamente más tarde.`,
                status: 'warning',
                duration: 4000,
                isClosable: true,
              });
            }

            setIsLoading(false);
          }
          // Do something with the weatherData, for example, update state or dispatch an action
        }
      } catch (error) {
        console.error('Error fetching weather data:', error);
        // Handle the error, you might want to show a toast or update state accordingly
      }
    };
    if (absoluteLocalities.length > 0) fetchData();
  }, [absoluteLocalities]);

  useEffect(() => {
    if (localitiesAndWeather && localitiesAndWeather.length > 0) {
      console.log(localitiesAndWeather);
      setIndexToRender(0);
    }
  }, [localitiesAndWeather]);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/map' });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const storedRoute = await RouteApi.getRouteById(routeId);
      console.log(storedRoute);
      if (storedRoute?.localities) {
        setApiData(storedRoute);
        setAbsoluteLocalities(storedRoute?.localities);
      }
    };
    if (routeId) {
      setIsLoading(true);
      fetchData();
    }
  }, [routeId]);

  return (
    <LayoutBasic>
      <Helmet>
        <title>Route Forecast - Planifica tu ruta</title>
        <meta
          name="description"
          content="Introduce tus datos en el mapa de Route Forecast para obtener pronósticos del tiempo precisos para tus rutas."
        />
      </Helmet>
      <Box>
        {isLoading && <LoadingMap />}
        {indexToRender !== null && (
          <WeatherInfo
            index={indexToRender}
            maxIndex={localitiesAndWeather.length - 1}
            data={localitiesAndWeather[indexToRender]}
            setIndexToRender={setIndexToRender}
            selectedHour={selectedHour}
            setSelectedHour={setSelectedHour}
          />
        )}
        <Box h={window.innerHeight}>
          <MapFrame
            setIsLoading={setIsLoading}
            setLocalitiesAndWeather={setLocalitiesAndWeather}
            setAbsoluteLocalities={setAbsoluteLocalities}
            localitiesAndWeather={localitiesAndWeather}
            setIndexToRender={setIndexToRender}
            indexToRender={indexToRender}
            routeData={apiData}
          />
        </Box>
      </Box>
    </LayoutBasic>
  );
}
