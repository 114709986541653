import React from 'react';
import { Box, Center, Text } from '@chakra-ui/react';

const WindArrow = ({ windDirection }) => {
  const directions = ['N', 'NO', 'O', 'SO', 'S', 'SE', 'E', 'NE'];
  const modifiers = [-8, -1, -2, -3, -4, 3, 2, 1];
  const angle = 45 * modifiers[directions.indexOf(windDirection)];
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      style={{
        transform: `rotate(${angle}deg)`,
        transformOrigin: 'center',
      }}
    >
      <line x1="20" y1="0" x2="20" y2="40" stroke="#000" strokeWidth="2" />
      <polygon points="15,35 20,40 25,35" fill="#000" />
    </svg>
  );
};

export default WindArrow;
