import React from 'react';
import { Box, Image, Text } from '@chakra-ui/react';
import { ReactComponent as SVGImage } from '../../../../../assets/svg/bike_white.svg';
import ResponsiveSVG from '../../../../Misc/ResponsiveSVG';

const artImage = require('../../../../../assets/png/bike_white.png');

export default function Create() {
  return (
    <Box
      w={'100%'}
      textAlign={'center'}
      bgColor={'#38A169'}
      /*  position={'relative'} */
    >
      <Text
        textStyle={'artWorkWhite'}
        paddingLeft={['15%']}
        paddingTop={50}
        mb={20}
      >
        Planea tu Ruta
      </Text>
      <ResponsiveSVG
        SVGImage={SVGImage}
        widthArray={['60%', null, null, '15%']}
        ml={['8%', null, null, '30%']}
      />
    </Box>
  );
}
