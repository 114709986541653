import { Box, Center } from '@chakra-ui/react';
import React from 'react';

export default function BetaInscription() {
  return (
    <Center>
      <Box w={['100%']}>
        <iframe
          title="InscripcionBeta"
          width={'100%'}
          src="https://5d61ab9c.sibforms.com/serve/MUIFAHDpQQ_OwlAnXdqgyltObBxV8tYNKAyvVlmePnsuDJJ8gubR2HwVHnlCltwy2Cr3jhGgMjSh-CAai7uHpceTUF_qW4JB5qUthALOy1u4A1c3240vE_FC0Pc1Q9KEzVS548cpS2S5N_RxSoEYqfyHtxF930jcbGwz38JYSTmnY6fNUiM2p52Lec_mCR_mmZo5PzTR4KlA2MRd"
          frameborder="0"
          allowfullscreen
          style={{
            display: 'block',
            margin: 0,
            padding: 0,
            maxWidth: '100%',
            minHeight: '80vh',
          }}
        ></iframe>
      </Box>
    </Center>
  );
}
