import { Box, Text } from '@chakra-ui/react';
import HelpComponent from '../components/Web/Help';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
ReactGA.initialize('G-YMJYGJM7BD');

export default function Help() {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/help' });
  }, []);
  return (
    <Box>
      <HelpComponent />
    </Box>
  );
}
