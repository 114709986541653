import { BASE_PATH, API_VERSION } from './apiconfig';

class RouteApi {
  static async createRoute(routeMetadata) {
    const url = `${BASE_PATH}/${API_VERSION}/route/`;
    const headers = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ routeMetadata }), // Shorthand for { routeMetadata: routeMetadata }
    };

    try {
      const response = await fetch(url, headers);

      if (!response.ok) {
        throw new Error(`Failed to create route: ${response.statusText}`);
      }

      // You can return or handle the response as needed
      return response.json();
    } catch (error) {
      console.error('Error creating route:', error);
      // Handle the error appropriately, e.g., throw it again or log it
      throw error;
    }
  }
  static async getRoute(polyline, travelMode) {
    const url = `${BASE_PATH}/${API_VERSION}/get-route/`;
    const headers = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ polyline, travelMode }),
    };

    try {
      const response = await fetch(url, headers);

      if (!response.ok) {
        throw new Error();
      }

      // Parse and return the JSON response
      return response.json();
    } catch (error) {
      // Handle the error appropriately, e.g., throw it again or log it
      throw new Error();
    }
  }
  static async getRouteById(routeId) {
    const url = `${BASE_PATH}/${API_VERSION}/get-route/`;
    const headers = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ routeId }),
    };

    try {
      const response = await fetch(url, headers);

      if (!response.ok) {
        throw new Error();
      }

      // Parse and return the JSON response
      return response.json();
    } catch (error) {
      // Handle the error appropriately, e.g., throw it again or log it
      throw new Error();
    }
  }
  static async getRouteWeather(localities) {
    const url = `${BASE_PATH}/${API_VERSION}/get-route-weather-AEMET/`;
    const headers = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ localities }),
    };

    try {
      const response = await fetch(url, headers);

      if (!response.ok) {
        throw new Error();
      }

      // Parse and return the JSON response
      return response.json();
    } catch (error) {
      // Handle the error appropriately, e.g., throw it again or log it
      throw new Error();
    }
  }
}

export default RouteApi;
