// PrivacyPolicy.js
import React from 'react';
import {
  ChakraProvider,
  Box,
  Heading,
  Text,
  VStack,
  Button,
  Link,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';

const PrivacyPolicy = () => {
  return (
    <ChakraProvider>
      <Box
        display={'flex'}
        w={['95%', null, null, null, '50%']}
        mr={['2.5%', null, null, null, '25%']}
        alignItems={'center'}
        justifyContent={'flex-start'}
      >
        <Link as={ReactRouterLink} to={'/'}>
          <Button
            color={'#242424'}
            alignSelf={'flex-end'}
            aria-label="Inicio"
            leftIcon={<ArrowBackIcon />}
            bgColor={'transparent'}
          >
            Volver
          </Button>
        </Link>
      </Box>
      <Box w={['90%', null, null, null, '50%']} mx="auto" mt="4">
        <Heading mb="4">Política de Privacidad de RouteForecast.app</Heading>

        {/* Secciones de la política de privacidad */}
        <VStack align="start" spacing="4">
          <Text>
            <strong>1. Información Recopilada</strong>
            <br />
            No recopilamos información personal identificable, a menos que
            decidas proporcionárnosla de manera voluntaria.
          </Text>

          <Text>
            <strong>2. Datos Anónimos</strong>
            <br />
            Recopilamos datos anónimos sobre el uso de nuestro sitio web con el
            objetivo de mejorar la experiencia del usuario y la calidad de
            nuestros servicios. Estos datos pueden incluir información sobre la
            interacción con el sitio, preferencias de navegación y estadísticas
            generales, pero no contienen información que te identifique
            personalmente.
          </Text>

          <Text>
            <strong>3. Uso de la Información</strong>
            <br />
            La información recopilada se utiliza exclusivamente para mejorar la
            funcionalidad y el contenido de nuestro sitio web. No compartiremos,
            venderemos ni alquilaremos tu información personal a terceros sin tu
            consentimiento explícito.
          </Text>

          <Text>
            <strong>4. Cookies y Tecnologías Similares</strong>
            <br />
            Utilizamos cookies y tecnologías similares para mejorar la
            funcionalidad de nuestro sitio web y recopilar datos anónimos.
            Puedes gestionar las preferencias de las cookies a través de la
            configuración de tu navegador.
          </Text>

          <Text>
            <strong>5. Enlaces a Sitios Externos</strong>
            <br />
            Nuestro sitio web puede contener enlaces a sitios web de terceros.
            No nos hacemos responsables de las prácticas de privacidad de estos
            sitios y te recomendamos revisar sus políticas de privacidad.
          </Text>

          <Text>
            <strong>6. Seguridad de la Información</strong>
            <br />
            Implementamos medidas de seguridad para proteger la información
            recopilada contra accesos no autorizados o divulgación.
          </Text>

          <Text>
            <strong>7. Cambios en la Política de Privacidad</strong>
            <br />
            Nos reservamos el derecho de actualizar esta política de privacidad
            en cualquier momento. Te recomendamos revisar periódicamente esta
            página para estar informado/a sobre cualquier cambio.
          </Text>

          <Text>
            <strong>8. Contacto</strong>
            <br />
            Si tienes preguntas o inquietudes sobre nuestra política de
            privacidad, puedes ponerte en contacto con nosotros en{' '}
            <a href="mailto:contacto@routeforecast.app">
              contacto@routeforecast.app
            </a>
            .
          </Text>
        </VStack>

        <Box mt="8" textAlign="center">
          <Text>
            Al utilizar nuestro sitio web, aceptas los términos de esta política
            de privacidad.
          </Text>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default PrivacyPolicy;
