import React from 'react';

import './ResponsiveSVG.scss';
import { Box } from '@chakra-ui/react';

export default function ResponsiveSVG({
  SVGImage,
  widthArray,
  ml,
  mt,
  mr,
  mb,
  ...props
}) {
  const { sx } = props;
  return (
    <Box
      w={widthArray || ['70%', null, null, '30%']}
      h="auto"
      ml={ml}
      mr={mr}
      mt={mt}
      mb={mb}
      sx={sx}
    >
      <SVGImage />
    </Box>
  );
}
