import Landing from '../pages/Landing';
import Help from '../pages/Help';
import Map from '../pages/Map';

import LayoutBasic from '../layouts/LayoutBasic';
import LayoutMap from '../layouts/LayoutMap';
import PrivacyPolicy from '../pages/PrivacyPolicy';

const routesClient = [
  {
    path: '/',
    component: Landing,
    layout: LayoutBasic,
  },
  {
    path: '/help',
    component: Help,
    layout: LayoutBasic,
  },
  {
    path: '/map',
    component: Map,
    layout: LayoutMap,
  },
  {
    path: '/map/route/:routeId',
    component: Map,
    layout: LayoutMap,
  },
  {
    path: '/politica-de-privacidad',
    component: PrivacyPolicy,
    layout: LayoutBasic,
  },
];

const routes = [
  ...routesClient,
  {
    path: '*',
    layout: LayoutBasic,
    component: Landing,
  },
];

export default routes;
