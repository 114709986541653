import { Box, Text, Image, position } from '@chakra-ui/react';
import React from 'react';
import { ReactComponent as SVGImage } from '../../../../../assets/svg/social_media.svg';
import ResponsiveSVG from '../../../../Misc/ResponsiveSVG';
const artImage = require('../../../../../assets/png/social_media.png');

export default function Share() {
  return (
    <Box
      position={'relative'}
      textAlign={'center'}
      bgColor={'#fff'}
      p={0}
      m={0}
    >
      <Text
        textStyle={'artWorkWhite'}
        color={'#242424'}
        pr={['25%']}
        mt={50}
        mb={5}
      >
        Compártela
      </Text>
      <ResponsiveSVG
        SVGImage={SVGImage}
        widthArray={['90%', null, null, '30%']}
        ml={['10%', null, null, '35%']}
      />
    </Box>
  );
}
