import React, { useEffect, useState } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  OverlayView,
} from '@react-google-maps/api';
import {
  Box,
  IconButton,
  VStack,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import polyline from '@mapbox/polyline';
import RouteForm from '../RouteForm/RouteForm';
import LocalitiesFinder from '../../../../util/LocalitiesFinder';
import LoadingMap from '../../../Misc/LoadingMap';
import RouteApi from '../../../../api/RouteApi';
import { municipios } from '../../../../assets/data/json/municipios';
import { MoonIcon, SearchIcon } from '@chakra-ui/icons';
import { FaLink, FaShareAlt } from 'react-icons/fa';
const MAP_STYLE = {
  LIGHT: [
    {
      featureType: 'poi.business',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ],
  DARK: [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#242f3e',
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#746855',
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#242f3e',
        },
      ],
    },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#d59563',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#d59563',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#263c3f',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#6b9a76',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#38414e',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#212a37',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9ca5b3',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#746855',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#1f2835',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#f3d19c',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [
        {
          color: '#2f3948',
        },
      ],
    },
    {
      featureType: 'transit.station',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#d59563',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#17263c',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#515c6d',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#17263c',
        },
      ],
    },
  ],
};

const center = {
  lat: 40.4167754,
  lng: -3.7037902,
};

const TravelMode = {
  BICYCLING: 'BICYCLING',
  DRIVING: 'DRIVING',
  TRANSIT: 'TRANSIT',
  WALKING: 'WALKING',
};

function MapFrame(props) {
  const [selectedMarkerPosition, setSelectedMarkerPosition] = useState(null);
  const [map, setMap] = useState(null);
  const [alreadyCalculated, setAlreadyCalculated] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [response, setResponse] = useState({ directions: null, id: null });
  const [errorMessage, setErrorMessage] = useState('');
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [localities, setLocalities] = useState([]);
  const [isLargerThanMobile] = useMediaQuery('(min-width: 768px)');
  const [longPressTimer, setLongPressTimer] = useState(null);
  const [userMarker, setUserMarker] = useState(null);
  const [isLongPress, setIsLongPress] = useState(false);
  const [directionsId, setDirectionsId] = useState(null);
  const [decodedPolyline, setDecodedPolyline] = useState(null);
  const [isContextualMenuVisible, setIsContextualMenuVisible] = useState(false);
  const [actualPolyline, setActualPolyline] = useState(null);
  const [mapStyle, setMapStyle] = useState(MAP_STYLE.DARK);
  const [apiData, setApiData] = useState(null);

  const {
    setAbsoluteLocalities,
    localitiesAndWeather,
    setLocalitiesAndWeather,
    setIndexToRender,
    setIsLoading,
    indexToRender,
    routeData,
  } = props;

  const mapOptions = {
    styles: mapStyle,
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
  };

  const toast = useToast();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
  });

  useEffect(() => {
    if (isLongPress /* && !userMarker.includes(isLongPress) */) {
      setUserMarker(isLongPress);
      setIsContextualMenuVisible(true);
    }
  }, [isLongPress, userMarker]);

  useEffect(() => {
    setIsOverlayVisible(false);
    if (indexToRender !== null && localitiesAndWeather) {
      console.log(
        localitiesAndWeather[indexToRender].weatherForecast[0].hours[0]
          .weatherCode
      );

      setSelectedMarkerPosition({
        lat: localitiesAndWeather[indexToRender]?.point[0],
        lon: localitiesAndWeather[indexToRender]?.point[1],
      });
      var latLng = new google.maps.LatLng(
        localitiesAndWeather[indexToRender]?.point[0],
        localitiesAndWeather[indexToRender]?.point[1]
      );
      map.panTo(latLng);
    }
  }, [indexToRender, localitiesAndWeather, map]);

  useEffect(() => {
    // Remove the previous polyline when new data is available

    // Render the new polyline if decodedPolyline is available
    if (decodedPolyline && map) {
      map.panTo({ lat: decodedPolyline[0][0], lng: decodedPolyline[0][1] });
      map.setZoom(9);
      const polyline = new window.google.maps.Polyline({
        path: transformToLatLngArray(decodedPolyline),
        map: map, // Your Google Map instance
        strokeColor: '#38A169', // Polyline color
        strokeOpacity: 1.0, // Opacity (0.0 to 1.0)
        strokeWeight: 5, // Line width
        zIndex: 3,
      });

      setActualPolyline(polyline);
    }
  }, [decodedPolyline, map]);

  useEffect(() => {
    toast({
      title: '¡Hola!',
      description:
        'Queremos recordarte que todavía estamos en fase beta, por lo que es posible que encuentres algunos errores en la aplicación. Estamos trabajando duro para solucionarlos lo antes posible.\n¡Agradecemos mucho tu confianza en nosotros!😊',
      duration: 8000,
      isClosable: true,
    });
  }, []);

  const localitiesFinder = new LocalitiesFinder(municipios);

  const [directionsServiceOptions, setDirectionsServiceOptions] = useState({
    destination: '',
    origin: '',
    travelMode: TravelMode.DRIVING,
    waypoints: [],
  });

  const handleDeleteWaypoint = waypointName => {
    // Create a copy of the directionsServiceOptions object
    const updatedDirectionsServiceOptions = { ...directionsServiceOptions };

    // Delete the waypoint from the directionsServiceOptions object
    delete updatedDirectionsServiceOptions.waypoints[`${waypointName}`];

    // Update both state variables
    setDirectionsServiceOptions(updatedDirectionsServiceOptions);
  };

  const directionsCallback = async (result, status) => {
    if (status === 'OK' && result !== null) {
      const id = Date.now();
      setDirectionsId(id);
      setResponse({ directions: result, id: id });
      let routeMetadata = {};
      if (
        !result.routes[0].legs[0].start_address.includes('Spain') ||
        !result.routes[0].legs[0].end_address.includes('Spain')
      ) {
        if (
          !result.routes[0].legs[0].start_address.includes('Spain') &&
          !result.routes[0].legs[0].end_address.includes('Spain')
        ) {
          toast({
            title: 'Lo sentimos.',
            description:
              'De momento, solo estamos disponibles en España. Pero muy pronto estaremos ofreciendo servicio a nivel mundial.',
            status: 'warning',
            duration: 5000,
            isClosable: true,
          });
          setAlreadyCalculated(false);
          setLocalitiesAndWeather(null);
          setIsFormVisible(true);
          setIsLoading(false);
          return;
        } else {
          toast({
            title: 'Lo sentimos.',
            description:
              'Por ahora, solo damos servicio en España. Te enseñaremos las localidades hasta el final del país.',
            status: 'warning',
            duration: 5000,
            isClosable: true,
          });
        }
        routeMetadata.status = 'INCOMPLETE';
      }

      const speedValues = {
        [TravelMode.DRIVING]: 90, // 90 km/h for driving
        [TravelMode.BICYCLING]: 15, // 15 km/h for bicycling
        [TravelMode.WALKING]: 5, // 5 km/h for walking
      };

      /**EXTRACT ROUTE INFORMATION FROM THE RESULT OBJECT */
      const polyline_encoded = result.routes[0].overview_polyline;

      const totalDistance = result.routes[0].legs[0].distance.value;
      const duration = result.routes[0].legs[0].duration.value;
      const route = polyline.decode(polyline_encoded);

      setDecodedPolyline(polyline.decode(polyline_encoded));

      const distanceThreshold =
        totalDistance > 200000
          ? totalDistance / 10
          : directionsServiceOptions.travelMode === TravelMode.DRIVING
          ? calculateDistanceThreshold(totalDistance)
          : 10000;
      if (totalDistance > 200000)
        toast({
          title: 'Límite.',
          description: `Limitamos las localidades presentadas a partir de 200km debido a problemas de saturación.\nLe sugerimos planear la ruta en varias consultas.`,
          duration: 4000,
          isClosable: true,
        });

      const initialPoint = route[0];
      initialPoint.timeToArrive = 0;
      map.panTo({ lat: initialPoint[0], lng: initialPoint[1] });
      map.setZoom(9);

      try {
        const storedRoute = await RouteApi.getRoute(
          polyline_encoded,
          directionsServiceOptions.travelMode
        );
        if (storedRoute && storedRoute.status === 'OK') {
          setApiData(storedRoute._id);
          setAbsoluteLocalities(storedRoute.localities);
          return;
        }
      } catch (error) {}

      /**CALCULATE INTERMEDIATE POINTS*/
      let intermediatePoints = [initialPoint];
      let accumulatedDistance = 0;
      for (let i = 1; i < route.length - 1; i++) {
        const prevPoint = intermediatePoints[intermediatePoints.length - 1];
        const currentPoint = route[i];
        const prevLatLng = { lat: prevPoint[0], lng: prevPoint[1] };
        const currentLatLng = { lat: currentPoint[0], lng: currentPoint[1] };
        /* global google */
        const distance = google.maps.geometry.spherical.computeDistanceBetween(
          prevLatLng,
          currentLatLng
        );

        accumulatedDistance += distance / 100;
        const speed = speedValues[directionsServiceOptions.travelMode];

        const timeToArriveInSeconds =
          (accumulatedDistance / 100 / speed) * 3600; // Convert to seconds
        currentPoint.timeToArrive = timeToArriveInSeconds;
        if (
          distance >= distanceThreshold &&
          totalDistance - accumulatedDistance >=
            distanceThreshold + distanceThreshold * 0.2
        ) {
          intermediatePoints.push(currentPoint);
        }
      }
      const finishPoint = route[route.length - 1];
      finishPoint.timeToArrive = duration;
      intermediatePoints.push(finishPoint);

      /**GET LOCALITIES BASED ON INTERMEDIATE POINTS */
      const geocoder = new google.maps.Geocoder();
      const localities = [];
      const localitiesToStore = [];

      let originCountry = null;
      let destinationCountry = null;

      for (let i = 0; i < intermediatePoints.length; i++) {
        const latLng = new google.maps.LatLng(
          intermediatePoints[i][0],
          intermediatePoints[i][1]
        );
        try {
          const results = await geocoder.geocode({ location: latLng });

          if (results.results.length > 0) {
            const country = extractFromGeopoint(results.results[0], 'country');
            if (originCountry === null) {
              originCountry = country;
              destinationCountry = country;
            } else if (originCountry !== country) {
              destinationCountry = country;
            }
            let extractedLocality = null;

            let index = 0;
            while (
              extractedLocality === null &&
              index < results.results.length - 1
            ) {
              const nearestLocality = results.results[index];
              const tempLocation = nearestLocality.geometry.location;
              const tempExtractedLocality =
                extractLocalityFromGeocodingResponse(nearestLocality);
              const tempExtractedProvince =
                localitiesFinder.findSpanishNameByName(
                  extractProvinceFromGeocodingResponse(nearestLocality)
                );

              const codigoMunicipio = localitiesFinder.findLocalityCodeByName(
                tempExtractedLocality,
                tempExtractedProvince
              );

              if (codigoMunicipio) {
                extractedLocality = {
                  locality: tempExtractedLocality,
                  province: tempExtractedProvince,
                  localityCode: codigoMunicipio,
                  timeToArrive: intermediatePoints[i].timeToArrive,
                  location: tempLocation,
                  country: country,
                };
              }

              index++;
            }
            index = 0;
            while (
              extractedLocality === null &&
              index < results.results.length - 1
            ) {
              const nearestLocality = results.results[index];
              const tempLocation = nearestLocality.geometry.location;
              const tempExtractedLocality =
                extractLocalityFromGeocodingResponse(nearestLocality);
              const tempExtractedProvince =
                localitiesFinder.findSpanishNameByName(
                  extractProvinceFromGeocodingResponse(nearestLocality)
                );
              const codigoMunicipio = localitiesFinder.findCodeByName(
                tempExtractedLocality,
                tempExtractedProvince
              );

              if (codigoMunicipio) {
                extractedLocality = {
                  locality: tempExtractedLocality,
                  province: tempExtractedProvince,
                  localityCode: codigoMunicipio,
                  timeToArrive: intermediatePoints[i].timeToArrive,
                  location: tempLocation,
                  country: country,
                };
              } else {
              }
              index++;
            }
            index = 0;
            while (
              extractedLocality === null &&
              index < results.results.length - 1
            ) {
              const nearestLocality = results.results[index];
              const tempLocation = nearestLocality.geometry.location;
              const tempExtractedLocality =
                extractLocalityBypassFromGeocodingResponse(nearestLocality);
              const tempExtractedProvince =
                localitiesFinder.findSpanishNameByName(
                  extractProvinceFromGeocodingResponse(nearestLocality)
                );
              const codigoMunicipio = localitiesFinder.findLocalityCodeByName(
                tempExtractedLocality,
                tempExtractedProvince
              );

              if (codigoMunicipio) {
                extractedLocality = {
                  locality: tempExtractedLocality,
                  province: tempExtractedProvince,
                  localityCode: codigoMunicipio,
                  timeToArrive: intermediatePoints[i].timeToArrive,
                  location: tempLocation,
                  country: country,
                };
              } else {
              }
              index++;
            }

            index = 0;

            while (
              extractedLocality === null &&
              index < results.results.length - 1
            ) {
              const nearestLocality = results.results[index];
              const tempLocation = nearestLocality.geometry.location;
              const tempExtractedLocality =
                extractLocalityFromGeocodingResponse(nearestLocality);
              const tempExtractedProvince =
                localitiesFinder.findSpanishNameByName(
                  extractProvinceFromGeocodingResponse(nearestLocality)
                );
              const codigoMunicipio = localitiesFinder.tfindCodeByName(
                tempExtractedLocality,
                tempExtractedProvince
              );

              if (codigoMunicipio) {
                extractedLocality = {
                  locality: tempExtractedLocality,
                  province: tempExtractedProvince,
                  localityCode: codigoMunicipio,
                  timeToArrive: intermediatePoints[i].timeToArrive,
                  location: tempLocation,
                  country: country,
                };
              } else {
              }
              index++;
            }

            index = 0;

            while (
              extractedLocality === null &&
              index < results.results.length - 1
            ) {
              const nearestLocality = results.results[index];
              const tempLocation = nearestLocality.geometry.location;
              const tempExtractedLocality =
                extractLocalityFromGeocodingResponse(nearestLocality);
              const tempExtractedProvince =
                localitiesFinder.findSpanishNameByName(
                  extractProvinceFromGeocodingResponse(nearestLocality)
                );
              const codigoMunicipio = localitiesFinder.findCodeByName(
                tempExtractedLocality,
                tempExtractedProvince
              );

              if (codigoMunicipio) {
                extractedLocality = {
                  locality: tempExtractedLocality,
                  province: tempExtractedProvince,
                  localityCode: codigoMunicipio,
                  timeToArrive: intermediatePoints[i].timeToArrive,
                  location: tempLocation,
                  country: country,
                };
              } else {
              }
              index++;
            }

            index = 0;

            while (
              extractedLocality === null &&
              index < results.results.length - 1
            ) {
              const nearestLocality = results.results[index];
              const tempLocation = nearestLocality.geometry.location;
              const tempExtractedLocality =
                extractLocalityBypassFromGeocodingResponse(nearestLocality);
              const tempExtractedProvince =
                localitiesFinder.findSpanishNameByName(
                  extractProvinceFromGeocodingResponse(nearestLocality)
                );
              const codigoMunicipio = localitiesFinder.findCodeByName(
                tempExtractedLocality,
                tempExtractedProvince
              );

              if (codigoMunicipio) {
                extractedLocality = {
                  locality: tempExtractedLocality,
                  province: tempExtractedProvince,
                  localityCode: codigoMunicipio,
                  timeToArrive: intermediatePoints[i].timeToArrive,
                  location: tempLocation,
                  country: country,
                };
              } else {
              }
              index++;
            }

            if (extractedLocality) {
              extractedLocality.country = country;
            }
            localities.push(extractedLocality);
            if (!extractedLocality) {
              localitiesToStore.push({
                localityName: extractLocalityFromGeocodingResponse(
                  results.results[0]
                ),
                localityProvince: extractProvinceFromGeocodingResponse(
                  results.results[0]
                ),
                location: results.results[0].geometry.location,
                country: country,
                timeToArrive: intermediatePoints[i].timeToArrive,
                point: intermediatePoints[i],
              });
            } else if (extractedLocality) {
              localitiesToStore.push({
                localityName: extractedLocality.locality,
                localityProvince: extractedLocality.province,
                location: extractedLocality.location,
                country: country,
                timeToArrive: intermediatePoints[i].timeToArrive,
                point: intermediatePoints[i],
                localityCode: extractedLocality.localityCode,
              });
            }
          } else {
            console.error('No results.');
          }
        } catch (error) {
          console.error(`Error geocoding point ${i + 1}:`, error);
        }
      }

      setLocalities(localities);

      /**MERGE INTERMEDIATE POINTS WITH LOCALITIES ASSOCIATED */
      const absLocality = [];
      for (let i = 0; i < intermediatePoints.length; i++) {
        if (
          intermediatePoints[i][0] !== null &&
          intermediatePoints[i][1] !== null &&
          localities[i] != null
        ) {
          const mergedLocation = {
            point: intermediatePoints[i],
            timeToArrive: intermediatePoints[i].timeToArrive,
            localityName: localities[i].locality,
            localityCode: localities[i].localityCode, // Changed from number to String due to compat problems. Exmaple: '05345' turns into '5345'
            localityProvince: localities[i].province,
            localityPoint: localities[i].location,
            country: localities[i].country,
          };

          absLocality.push(mergedLocation);
        }
      }

      routeMetadata = {
        ...routeMetadata,
        polyline: polyline_encoded,
        origin: localitiesToStore[0]?.localityName
          ? localitiesToStore[0]?.localityName
          : directionsServiceOptions.origin,
        originCountry: originCountry,
        destinationCountry: destinationCountry,
        destination: localitiesToStore[localitiesToStore.length - 1]
          ?.localityName
          ? localitiesToStore[localitiesToStore.length - 1]?.localityName
          : directionsServiceOptions.destination,
        localities: localitiesToStore,
        avoidFerries: directionsServiceOptions.avoidFerries,
        avoidTolls: directionsServiceOptions.avoidTolls,
        avoidHighways: directionsServiceOptions.avoidHighways,
        distanceThreshold: distanceThreshold,
        averageSpeed: speedValues[directionsServiceOptions.travelMode],
        travelMode: directionsServiceOptions.travelMode,
        waypoints:
          directionsServiceOptions.waypoints.length > 0
            ? directionsServiceOptions.waypoints.map(waypoint => waypoint.value)
            : [],
      };

      if (
        result.routes[0].legs[0].start_address.includes('Spain') &&
        result.routes[0].legs[0].end_address.includes('Spain')
      ) {
        if (intermediatePoints.length > absLocality.length) {
          const diff =
            ((intermediatePoints.length - absLocality.length) * 100) /
            intermediatePoints.length;
          if (diff > 10) {
            toast({
              title: 'Aviso.',
              description: `Algunos puntos (${
                intermediatePoints.length - absLocality.length
              }) no se han cargado adecuadamente. Se generará automáticamente un informe para su revisión.`,
              status: 'warning',
              duration: 2000,
              isClosable: true,
            });
          }
          routeMetadata.status = 'INCOMPLETE';
        }
      }
      if (!routeMetadata.status) {
        routeMetadata.status = 'OK';
      }
      const newStoredRoute = await RouteApi.createRoute(routeMetadata);
      if (newStoredRoute) {
        console.log(newStoredRoute._id);
        setApiData(newStoredRoute._id);
      }
      setAbsoluteLocalities(absLocality);
    } else if (status === 'NOT_FOUND' || status === 'ZERO_RESULTS') {
      setErrorMessage();
      toast({
        title: 'Oops.😳',
        description:
          'No hemos logrado dar con el punto de partida o destino. ¿Podrías probar a escribir el nombre del lugar junto con la provincia correspondiente?',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setAlreadyCalculated(false);
      setLocalitiesAndWeather(null);
      setIsFormVisible(true);
      setIsLoading(false);
    } else if (status !== 'OK') {
      setIsLoading(false);
      toast({
        title: 'Oops.😳',
        description:
          'Lo sentimos, ha habido un error inesperado. Por favor, inténtalo de nuevo más tarde.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleFormSubmit = () => {
    if (
      directionsServiceOptions.destination !== '' &&
      directionsServiceOptions.origin !== ''
    ) {
      setResponse(null);
      if (actualPolyline) actualPolyline.setMap(null);
      setIsLoading(true);
      setIndexToRender(null);
      setLocalitiesAndWeather(null);
      setAlreadyCalculated(true);
      setIsFormVisible(false);
      const directionsService = new window.google.maps.DirectionsService();

      const waypoints = [];

      // Iterate through the waypoints and construct them as objects
      for (const key in directionsServiceOptions.waypoints) {
        if (directionsServiceOptions.waypoints.hasOwnProperty(key)) {
          const value = directionsServiceOptions.waypoints[key].value;
          waypoints.push({
            location: value, // Set the location property to the waypoint's value
            stopover: true, // You can specify other properties like stopover as needed
          });
        }
      }

      directionsService.route(
        {
          origin: directionsServiceOptions.origin,
          destination: directionsServiceOptions.destination,
          travelMode: directionsServiceOptions.travelMode,
          waypoints: waypoints.length > 0 ? waypoints : null,
          avoidFerries: directionsServiceOptions.avoidFerries,
          avoidHighways: directionsServiceOptions.avoidHighways,
          avoidTolls: directionsServiceOptions.avoidTolls,
          provideRouteAlternatives: false,
        },
        directionsCallback
      );
    }
  };

  const onTravelModeChange = e => {
    setAlreadyCalculated(false);
    setDirectionsServiceOptions({
      ...directionsServiceOptions,
      travelMode: e,
    });
  };

  /* USER ACTIONS */

  const handleOnDrag = () => {
    if (!isLargerThanMobile) {
      setIsContextualMenuVisible(false);
    }
  };

  const handleMouseDown = e => {
    /*  setLongPressTimer(
      setTimeout(() => {
        setIsLongPress(e);
      }, 500)
    ); */
    // Adjust the timeout duration as needed
  };

  const handleMouseUp = () => {
    clearTimeout(longPressTimer);
    if (isLongPress) {
      // Long press action has already been triggered, reset the flag
      setIsLongPress(false);
    } else {
      // Short press action (e.g., click) - handle it here
      console.log('Short press detected!');
    }
  };

  const handleUserMarkerOnClick = e => {
    setIsContextualMenuVisible(true);
  };

  /*MAP OPTIONS*/
  const handleZoom = isLargerThanMobile ? 7 : 5;

  const handleOnLoad = map => setMap(map);

  const mapContainerStyle = {
    width: indexToRender !== null && isLargerThanMobile ? '70%' : '100%',
    height: '100%',
    minHeight:
      indexToRender !== null && !isLargerThanMobile
        ? '50vh'
        : isFormVisible && !isLargerThanMobile
        ? window.innerHeight
        : window.innerHeight,
  };

  useEffect(() => {
    if (routeData) {
      setIsFormVisible(false);
      console.log(routeData.polyline);
      if (routeData.polyline)
        setDecodedPolyline(polyline.decode(routeData?.polyline));
    }
  }, [routeData]);

  return isLoaded ? (
    <Box>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        onLoad={handleOnLoad}
        zoom={handleZoom}
        options={mapOptions}
        onCenterChanged={() => {}}
        onBoundsChanged={handleOnDrag}
        onClick={e => {}}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onDrag={handleOnDrag}
      >
        {localitiesAndWeather?.map(
          (locality, index) =>
            locality && (
              <Marker
                key={index}
                position={{
                  lat: locality?.point[0],
                  lng: locality?.point[1],
                }}
                icon={{
                  url: require(`../../../../assets/weathericons/${locality?.weatherForecast[0].hours[0].weatherCode}_g.png`),
                  scaledSize: new window.google.maps.Size(40, 40),
                }}
                onClick={() => {
                  setIsFormVisible(false);
                  setIndexToRender(index);
                }}
                zIndex={6}
              />
            )
        )}
        {indexToRender !== null &&
          localitiesAndWeather[indexToRender] !== null && (
            <MapOverlay position={localitiesAndWeather[indexToRender].point} />
          )}
      </GoogleMap>
      {isFormVisible && (
        <RouteForm
          TravelMode={TravelMode}
          onTravelModeChange={onTravelModeChange}
          handleSubmit={handleFormSubmit}
          alreadyCalculated={alreadyCalculated}
          isVisible={isFormVisible}
          errorMessage={errorMessage}
          handleDeleteWaypointFromDirectionsService={handleDeleteWaypoint}
          setDirectionsServiceOptions={setDirectionsServiceOptions}
          directionsServiceOptions={directionsServiceOptions}
          setAlreadyCalculated={setAlreadyCalculated}
        />
      )}

      <Box
        position={'absolute'}
        top={2}
        left={2}
        p={2}
        bgColor={'#c4c4c440'}
        zIndex={1}
      >
        <VStack>
          <CustomIconButton
            mapStyle={mapStyle}
            icon={<SearchIcon />}
            onClick={() => {
              setIsFormVisible(!isFormVisible);
              setIndexToRender(null);
            }}
          />
          <CustomIconButton
            mapStyle={mapStyle}
            icon={<MoonIcon />}
            onClick={() => {
              setMapStyle(
                mapStyle === MAP_STYLE.LIGHT ? MAP_STYLE.DARK : MAP_STYLE.LIGHT
              );
            }}
          />
          {localitiesAndWeather !== null && (
            <CustomIconButton
              mapStyle={mapStyle}
              icon={navigator.share ? <FaShareAlt /> : <FaLink />}
              onClick={() => {
                if (navigator.share) {
                  console.log(routeData);
                  navigator
                    .share({
                      title: 'WebPage Title',
                      url: routeData
                        ? window.location.href
                        : `https://www.routeforecast.app/map/route/${apiData}`,
                    })
                    .then(() => console.log('Successful share'))
                    .catch(error => console.log('Error sharing', error));
                } else {
                  console.log(routeData);
                  navigator.clipboard.writeText(
                    routeData
                      ? window.location.href
                      : `https://www.routeforecast.app/map/route/${apiData}`
                  );
                  toast({
                    title: 'Enlace copiado con éxito',
                    duration: 2000,
                    isClosable: true,
                    status: 'info',
                    icon: <FaLink />,
                  });
                }
              }}
            />
          )}
        </VStack>
      </Box>
    </Box>
  ) : (
    <LoadingMap />
  );
}

function CustomIconButton({ onClick, icon, mapStyle }) {
  const [color, setColor] = useState(
    mapStyle === MAP_STYLE.LIGHT ? '#1A202C' : '#EDF2F7'
  );
  return (
    <IconButton
      onMouseOver={() => {
        setColor(mapStyle === MAP_STYLE.LIGHT ? '#1A202C' : '#EDF2F7');
      }}
      onMouseLeave={() => {
        setColor(mapStyle === MAP_STYLE.LIGHT ? '#EDF2F7' : '#1A202C');
      }}
      icon={icon}
      onClick={onClick}
      backgroundColor={mapStyle === MAP_STYLE.LIGHT ? '#EDF2F7' : '#1A202C'}
      color={mapStyle === MAP_STYLE.LIGHT ? '#1A202C' : '#EDF2F7'}
    />
  );
}

function transformToLatLngArray(decodedPolyline) {
  if (!Array.isArray(decodedPolyline)) {
    throw new Error('Invalid input format. Expecting an array.');
  }

  return decodedPolyline.map(tuple => {
    if (Array.isArray(tuple) && tuple.length === 2) {
      const [lat, lng] = tuple;
      return { lat, lng };
    } else {
      throw new Error(
        'Invalid tuple format. Expecting an array with two values.'
      );
    }
  });
}

function MapOverlay({ position }) {
  return (
    <OverlayView
      position={{ lat: position[0], lng: position[1] }}
      mapPaneName={OverlayView.OVERLAY_LAYER}
      getPixelPositionOffset={(width, height) => ({
        x: -width / 2,
        y: -height / 2,
      })}
      zIndex={4}
    >
      <div
        style={{
          width: '50px',
          height: '50px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          transform: 'translate(-50%, -90%)', // Center the overlay on the marker
          background: '#38A169',
          opacity: 0.4,
          /* border: '3px solid', */
          borderColor: '#38A169',
          borderRadius: 50,
          zIndex: 10,
        }}
      ></div>
    </OverlayView>
  );
}

function calculateDistanceThreshold(distance) {
  const distanceInMeters = distance * 1000; // Convert km to meters

  // Calculate the distanceThreshold using an implicit formula
  const factor =
    distanceInMeters <= 100000
      ? 5
      : distanceInMeters <= 200000
      ? 7
      : distanceInMeters <= 300000
      ? 10
      : Math.ceil(distanceInMeters / 30000);
  const distanceThreshold = distanceInMeters / factor;

  return distanceThreshold;
}

function extractLocalityFromGeocodingResponse(geocodingResponse) {
  const localityComponent = geocodingResponse.address_components.find(
    component => component.types.includes('locality')
  );

  return localityComponent ? localityComponent.long_name : null;
}

function extractProvinceFromGeocodingResponse(geocodingResponse) {
  const localityComponent = geocodingResponse.address_components.find(
    component => component.types.includes('administrative_area_level_2')
  );

  return localityComponent ? localityComponent.long_name : null;
}

function extractLocalityBypassFromGeocodingResponse(geocodingResponse) {
  const localityComponent = geocodingResponse.address_components.find(
    component => component.types.includes('administrative_area_level_3')
  );

  return localityComponent ? localityComponent.long_name : null;
}

function extractFromGeopoint(geocodingResponse, area) {
  const localityComponent = geocodingResponse.address_components.find(
    component => component.types.includes(area)
  );

  return localityComponent ? localityComponent.long_name : null;
}

export default React.memo(MapFrame);
