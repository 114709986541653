import codigosProvincias from '../assets/data/json/provincias';
import { compareTwoStrings } from 'string-similarity';
class LocalitiesFinder {
  constructor(localitiesData) {
    this.localitiesData = localitiesData;
  }

  findLocalityNameByName(localityName, provinceName) {
    if (!localityName || !provinceName) {
      return null;
    }

    try {
      const normalizedInput = localityName.normalize('NFD').toLowerCase(); // Normalize input
      const provinceCode = this.findProvinceCodeByName(provinceName);

      if (!provinceCode) {
        return null;
      }

      for (const locality of this.localitiesData) {
        const localityNameNormalized =
          locality.NOMBRE.normalize('NFD').toLowerCase();
        const localityParts = localityNameNormalized.split('/');
        if (localityParts[0] && localityParts[1]) {
          if (
            (normalizedInput.includes(localityParts[0]) ||
              normalizedInput.includes(localityParts[1])) &&
            locality.CPRO === Number(provinceCode)
          ) {
            return `${locality.NOMBRE}`;
          } else if (normalizedInput.includes(localityParts[1])) {
            return `${locality.NOMBRE}`;
          }
        } else if (
          (normalizedInput.includes(localityNameNormalized) ||
            normalizedInput === localityNameNormalized) &&
          locality.CPRO === Number(provinceCode)
        ) {
          return `${locality.NOMBRE}`;
        } else if (normalizedInput === localityNameNormalized) {
          return `${locality.NOMBRE}`;
        }
      }

      return null; // No matches found
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  findCodeByName(localityName, provinceName) {
    if (!localityName || !provinceName) {
      return null;
    }

    try {
      const normalizedInput = localityName.normalize('NFD').toLowerCase(); // Normalize input
      const provinceCode = this.findProvinceCodeByName(provinceName);

      if (!provinceCode) {
        return null;
      }

      for (const locality of this.localitiesData) {
        const localityNameNormalized =
          locality.NOMBRE.normalize('NFD').toLowerCase();
        const localityParts = localityNameNormalized.split('/');

        if (
          (normalizedInput.includes(localityNameNormalized) ||
            normalizedInput === localityNameNormalized) &&
          locality.CPRO === Number(provinceCode)
        ) {
          return `${locality.CPRO}${locality.CMUN}`;
        } else if (normalizedInput === localityNameNormalized) {
          return `${locality.CPRO}${locality.CMUN}`;
        } else if (localityParts[0] && localityParts[1]) {
          if (
            (normalizedInput.includes(localityParts[0]) ||
              normalizedInput.includes(localityParts[1])) &&
            locality.CPRO === Number(provinceCode)
          ) {
            return `${locality.CPRO}${locality.CMUN}`;
          } else if (normalizedInput.includes(localityParts[1])) {
            return `${locality.CPRO}${locality.CMUN}`;
          }
        }
      }

      return null; // No matches found
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  findLocalityCodeByName(localityName, provinceName) {
    let bestMatch = null;
    let highestScore = -1;

    this.localitiesData.forEach(dataItem => {
      if (dataItem.NOMBRE && dataItem.locality && dataItem.province) {
        const localityScore = compareTwoStrings(
          localityName.toLowerCase(),
          dataItem.NOMBRE.toLowerCase()
        );

        const provinceScore = compareTwoStrings(
          provinceName.toLowerCase(),
          dataItem.province.toLowerCase()
        );

        const totalScore = localityScore * provinceScore;

        if (totalScore > highestScore) {
          bestMatch = `${dataItem.CPRO}${dataItem.CMUN}`;
          highestScore = totalScore;
        }
      }
    });

    return bestMatch;
  }

  tfindCodeByName(localityName, provinceName) {
    const splitWord = word => {
      const splitters = ['/', '-', ',', ' '];
      let result = null;
      splitters.forEach(splitter => {
        const splittedWord = word.toLowerCase().split(splitter);
        if (splittedWord.length > 1) {
          result = splittedWord;
        }
      });
      return result ? result : word;
    };

    if (!localityName || !provinceName) {
      return null;
    }
    const normalizedLocality = splitWord(
      localityName.normalize('NFD').toLowerCase()
    );
    const provinceCode = this.findProvinceCodeByName(provinceName);
    if (!provinceCode) {
      return;
    }

    const potentialCandidates = this.localitiesData.filter(locality => {
      const normalizedNOMBRE = locality.NOMBRE.normalize('NFD').toLowerCase();
      const localityCPRO = parseInt(locality.CPRO);
      if (typeof normalizedLocality === 'string') {
        const includesName = normalizedNOMBRE.includes(normalizedLocality);

        return includesName && localityCPRO === provinceCode;
      } else if (Array.isArray(normalizedLocality)) {
        const results = [];

        normalizedLocality.forEach(word => {
          if (normalizedNOMBRE.includes(word)) {
            const pass = ['de', 'del', 'la', 'el', 'a'];
            if (!pass.includes(word)) results.push(locality);
          }
        });

        return results.length > 0;
      } else {
        return false;
      }
    });
    if (potentialCandidates.length === 0) {
      console.warn('Not locality found');
      return;
    }

    if (potentialCandidates.length === 1) {
      //Best match
      return `${potentialCandidates[0].CPRO}${potentialCandidates[0].CMUN}`;
    } else {
      //Multiple options
      //Slice the potentialCandidates names
      const slicedCandidates = potentialCandidates.map(candidate => ({
        ...candidate,
        NOMBRE_COMPUESTO: splitWord(candidate.NOMBRE),
      }));

      const scores = [];
      //Jaccard Similarity and give scores
      let scoreSum = { score: 0, count: 0 };
      slicedCandidates.forEach(candidate => {
        if (
          Array.isArray(candidate.NOMBRE_COMPUESTO) &&
          Array.isArray(normalizedLocality)
        ) {
          let score = 0;
          candidate.NOMBRE_COMPUESTO.forEach(candidateNombre => {
            normalizedLocality.forEach(word => {
              //score += calculateJaccardSimilarity(candidateNombre, word);
              const pass = ['de', 'del', 'la', 'el', 'a'];
              if (word === candidateNombre && !pass.includes(word)) {
                score += 4;
              }
            });
          });
          if (score > 3.5) {
            scores.push({ ...candidate, SCORE: score });
          }
        } else if (
          typeof candidate.NOMBRE_COMPUESTO === 'string' &&
          Array.isArray(normalizedLocality)
        ) {
          let score = 0;
          normalizedLocality.forEach(word => {
            if (!candidate.NOMBRE.length > word)
              score += calculateJaccardSimilarity(candidate.NOMBRE, word);
          });
          scoreSum.count++;
          scoreSum.score += score;
          if (score > 1) {
            scores.push(candidate);
          }
        }
      });
      //Return the winner
      const highestScoreElement = scores.reduce(
        (maxElement, currentElement) => {
          if (currentElement.score > maxElement.score) {
            return currentElement;
          } else {
            return maxElement;
          }
        },
        scores[0]
      );
      return highestScoreElement
        ? `${highestScoreElement.CPRO}${highestScoreElement.CMUN}`
        : undefined;
    }
  }

  findProvinceCodeByName(name) {
    if (!name) return null;
    name = name.toLowerCase();

    for (const code in codigosProvincias) {
      const provinceInfo = codigosProvincias[code];
      if (
        provinceInfo['es'].toLowerCase().includes(name) ||
        provinceInfo['en'].toLowerCase().includes(name)
      ) {
        return parseInt(code);
      }
    }
    return null;
  }

  findProvinceNamesByCode(code) {
    const provinceInfo = codigosProvincias[code];
    if (provinceInfo) {
      return {
        es: provinceInfo['es'],
        en: provinceInfo['en'],
      };
    }
    return null;
  }

  findSpanishNameByName(name) {
    if (!name) return null;
    name = name.toLowerCase();

    for (const code in codigosProvincias) {
      const provinceInfo = codigosProvincias[code];
      if (
        provinceInfo['es'].toLowerCase().includes(name) ||
        provinceInfo['en'].toLowerCase().includes(name)
      ) {
        return provinceInfo['es'];
      }
    }
    return null;
  }
}

function calculateJaccardSimilarity(str1, str2) {
  const set1 = new Set(str1.split(''));
  const set2 = new Set(str2.split(''));
  const intersection = [...set1].filter(char => set2.has(char)).length;
  const union = set1.size + set2.size - intersection;
  return intersection / union;
}

export default LocalitiesFinder;
