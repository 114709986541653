import { Box } from '@chakra-ui/react';
import React from 'react';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <Box
        textAlign="center"
        alignContent={'center'}
        alignItems={'center'}
        justifyContent={'center'}
        flexDir={'column'}
        display="flex"
        minHeight="150px"
        backgroundColor="#38A169"
        padding={0}
        margin={0}
      >
        <span style={{ color: '#FFFFFF' }}>
          <a
            href="/politica-de-privacidad"
            style={{ color: '#FFFFFF', textDecoration: 'underline' }}
          >
            Política de privacidad
          </a>
          <br />
        </span>
        <span style={{ color: '#FFFFFF' }}>
          © {currentYear} RouteForecast - Desarrollado por{' '}
          <a
            href="https://www.pablooses.com/"
            target="_blank"
            rel="noreferrer"
            style={{ color: '#FFFFFF', textDecoration: 'underline' }}
          >
            Pablo Osés
          </a>
        </span>
      </Box>
    </footer>
  );
}
