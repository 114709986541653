import React, { useState } from 'react';
import {
  Center,
  Box,
  Text,
  Link,
  Button,
  IconButton,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalContent,
  VStack,
} from '@chakra-ui/react';

import { ArrowBackIcon } from '@chakra-ui/icons';
import { Link as ReactRouterLink } from 'react-router-dom';
import ResponsiveSVG from '../../Misc/ResponsiveSVG/ResponsiveSVG';
import { ReactComponent as SVGRouteSign } from '../../../assets/svg/road_sign.svg';
import { ReactComponent as SVGTeam } from '../../../assets/svg/team_up_route_opt.svg';
import BetaInscription from '../BetaInscription/BetaInscription';

export default function HelpComponent() {
  const [modalVisible, setModalVisible] = useState(false);

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };
  return (
    <Center>
      <VStack>
        <Box
          position={'absolute'}
          display={'flex'}
          w={['95%', null, null, null, '50%']}
          mr={['2.5%', null, null, null, '25%']}
          alignItems={'center'}
          justifyContent={'flex-start'}
        >
          <Link as={ReactRouterLink} to={'/'}>
            <Button
              color={'#242424'}
              alignSelf={'flex-end'}
              aria-label="Inicio"
              leftIcon={<ArrowBackIcon />}
              bgColor={'transparent'}
            >
              Volver
            </Button>
          </Link>
        </Box>
        <Box
          w={['92.5%', null, null, null, '50%']}
          marginTop={20}
          textAlign={'center'}
          id="quienes-somos"
        >
          <ResponsiveSVG
            SVGImage={SVGRouteSign}
            widthArray={['70%', null, null, '40%']}
            ml={['15%', null, null, '30%']}
          />
          <Text textStyle={'paragraphHeading'} mt={10} mb={0}>
            ¿Cómo funciona?
          </Text>
          <Center textAlign={'left'}>
            <Box w={['92.5%', null, null, null, '50%']} mt={15}>
              <ol>
                <li>
                  <Text textStyle={'paragraphBody'} pb={45}>
                    <b>Accede a la sección 'Planea tu ruta'</b>
                    <br />
                    <Center pt={10}>
                      <Button colorScheme="green" isDisabled={true}>
                        Planea tu Ruta
                      </Button>
                    </Center>
                  </Text>
                </li>
                <li>
                  <Text textStyle={'paragraphBody'} pb={45}>
                    <b>Introduce el origen y el destino</b>
                    <br /> En la sección de planificación de rutas, encontrarás
                    campos para ingresar el punto de origen y el punto de
                    destino. Escribe las direcciones o nombres de lugares
                    correspondientes en estos campos. Puedes ingresar
                    direcciones completas, códigos postales o nombres de lugares
                    reconocibles.
                  </Text>
                </li>

                <li>
                  <Text textStyle={'paragraphBody'} pb={45}>
                    <b>Selecciona el medio de transporte</b>
                    <br />
                    Una vez que hayas ingresado los puntos de origen y destino,
                    verás una opción para seleccionar el medio de transporte.
                    Puedes elegir entre varias opciones, como coche, andando,
                    bicicleta o motocicleta. Selecciona el medio que planeas
                    utilizar para tu ruta.
                  </Text>
                </li>
                <li>
                  <Text textStyle={'paragraphBody'} pb={45}>
                    <b>Selecciona opciones de rutas adicionales</b>
                    <br />
                    Evitar peajes, evitar autopistas, o incluso elegir una ruta
                    que genere el menor CO2. Marca las opciones que sean
                    relevantes para tu preferencia.
                  </Text>
                </li>
                <ResponsiveSVG
                  SVGImage={SVGTeam}
                  widthArray={['70%', null, null, '40%']}
                  ml={['15%', null, null, '30%']}
                  mb={10}
                />
                <li>
                  <Text textStyle={'paragraphBody'} pb={45}>
                    <b>Calcula la ruta</b>
                    <br />
                    Una vez que hayas ingresado la información requerida, busca
                    y selecciona el botón o enlace que diga "Calcular ruta" o
                    similar. La web procesará los datos y generará la ruta según
                    tus especificaciones. Una vez la ruta haya sido calculada
                    verás el recorrido junto con iconos que muestran la
                    información meteorológica a la hora de paso.
                  </Text>
                </li>
                <li>
                  <Text textStyle={'paragraphBody'} pb={45}>
                    <b>Comparte y guarda la ruta</b>
                    <br />
                    Puedes compartirla por medio de un enlace o una imagen
                    generada para compartir en redes sociales.
                  </Text>
                </li>
              </ol>
            </Box>
          </Center>
          <Text textAlign={'center'} textStyle={'paragraphBody'}>
            ¡Apúntate a la beta para probar la aplicación antes que nadie!
          </Text>
          <Center m={10}>
            <Button colorScheme="green" onClick={openModal}>
              Acceso Anticipado
            </Button>
          </Center>
        </Box>
      </VStack>

      <Modal isOpen={modalVisible} onClose={closeModal} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Acceso Anticipado</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <BetaInscription closeModal={closeModal} />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" onClick={closeModal}>
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Center>
  );
}
